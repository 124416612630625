import React, { useState, useEffect } from "react";
import Retool from "react-retool";

if(window.location.hostname.split('.')[1] !== 'softinka'){
    window.location.href = 'https://www.bitrix24.ru/?p=11845526';
}

const codeProject = window.location.hostname.split('.')[0]; // ваш код проекта
const prodName = getProdParam();

const App = () => {
  // Создаем состояние для newData
  const [newData, setNewData] = useState({ content: "initial value" });
  
  // Делаем setNewData доступной глобально, чтобы можно было изменить sampleData извне
  useEffect(() => {
    window.setNewData = setNewData;
  }, []);

  return (
    <div>
        <Retool
            url={prodName 
                ? `https://r.softinka.app/app/${prodName}` 
                : `https://r.softinka.app/p/${codeProject}`
            }
            data={newData} // Передаем состояние sampleData
            sandbox="allow-scripts allow-same-origin allow-downloads allow-popups allow-forms"
        />
    </div>
  );
};

export default App;

function getProdParam() {
  const urlParams = new URLSearchParams(window.location.search);
  const prod = urlParams.get('prod');
  return prod !== null ? prod : false; 
}
